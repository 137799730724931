$().ready(function(){
	setupSlideMenu();
	$("nav.child-page a").click(function(e){
		var url = $(this).attr("href");
		var title = $(this).text();
		loadSubPageUrl(url);
		e.preventDefault();

		//track ajax pages
		if (ga) {
			ga("send", "pageview", {"page": url, "title": title+"-ajax"});
		}
	});
	$(document).one("touchstart",function(){$("html").addClass("has-touch");});
});

$(window).on("load", function(){
	if ($("nav.child-page a").length ==0) {
		$(".sub-content .loading").remove();
		return;
	}
	var url = $("nav.child-page a:first").attr("href");
	loadSubPageUrl(url,true);
});
function loadSubPageUrl(url, initial_load){
	if (!$(".sub-content").length) return;
	if (typeof initial_load === "undefined") { initial_load = false; }
	$(".sub-content .loading").remove();
	$(".loaded-content").hide();
	//Change display of selected link
	var link = $("nav.child-page a[href='"+url+"']");
	$("nav.child-page li").removeClass("active").removeClass("current");
	link.parent().addClass("active");
	link.eq(0).parent().addClass("current");
	link.parent().parents("li").addClass("active");
	//display loading spinner while we load the new content
	$(".sub-content").append("<img src=\"/static/console/img/loading.gif\" class=\"loading\" alt=\"Loading...\"/>");

	//Check if we have already loaded this content
	if ($(".loaded-content[data-url='"+url+"']").length) {
		$(".loaded-content[data-url='"+url+"']").show();
		$(".sub-content .loading").remove();
		return;
	}
	$.ajax({
		url:url+"?format=json",
		dataType:"JSON",
		success:function(data){
			//add content to HTML
			$(".sub-content").append("<div class='loaded-content' data-url='"+url+"'><h2 class='child-page'>" + data.content_item.title + "</h2><h3 class='child-page'>" + (data.content_item.subtitle || "") + "</h3>" +data.page_content+"</div>");
			$(".sub-content .loading").remove();
			//responsive tables
			$(".sub-content table").each(function(index,el){
				var $el = $(el);
				if ($el.parent(".table-wrap").length == 0){
					$el.wrap("<div class='table-wrap'></div>");
				}
			});
		}
	});
}
function setupSlideMenu(){

	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}